import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Breadcrumbs are used to show taxonomical context on pages that are many levels deep in a site’s hierarchy. Breadcrumbs show and link to parent, grandparent, and sometimes great-grandparent pages. Breadcrumbs are most appropriate on pages that:`}</p>
    <ul>
      <li parentName="ul">{`Are many levels deep on a site`}</li>
      <li parentName="ul">{`Do not have a section-level navigation`}</li>
      <li parentName="ul">{`May need the ability to quickly go back to the previous (parent) page`}</li>
    </ul>
    <p>{`To use Breadcrumb with `}<a parentName="p" {...{
        "href": "https://github.com/ReactTraining/react-router"
      }}>{`react-router`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react-router-dom"
      }}>{`react-router-dom`}</a>{`, pass
`}<inlineCode parentName="p">{`as={NavLink}`}</inlineCode>{` and omit the `}<inlineCode parentName="p">{`selected`}</inlineCode>{` prop.
This ensures that the NavLink gets `}<inlineCode parentName="p">{`activeClassName='selected'`}</inlineCode></p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Breadcrumb>
  <Breadcrumb.Item href="#business">Business</Breadcrumb.Item>
  <Breadcrumb.Item href="#customers">Customers</Breadcrumb.Item>
  <Breadcrumb.Item href="#mailchimp" selected>
    MailChimp
  </Breadcrumb.Item>
</Breadcrumb>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Breadcrumb and Breadcrumb.Item components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <h3>{`Breadcrumb`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Breadcrumb`}</inlineCode>{` component does not receive any additional props besides `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props.`}</p>
    <h3>{`Breadcrumb.Item`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`a`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the HTML tag for the component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URL to be used for the Link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to style the link as selected or unselected`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      